.primary-details {
  margin-top: 20px;
}

.event-and-signups {
  display: flex;
}

.date-pair {
  margin: 10px 0;
}

.event-full .card-img-top {
  height: 200px;
  object-fit: cover;
}

.event-full h4 {
  font-size: 1.1em;
  font-weight: bold;
}

.card-text {
  margin-top: 10px;
}

.event-summary .card-img-top {
  height: 200px;
  object-fit: cover;
}

.event .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.event .card-body {
  display: flex;
  flex-flow: column;
}

.boats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: 10px;
}

.boats .boat {
  margin-bottom: 10px;
  margin-right: 10px;
}

.missing td {
  background: #fbffc9;
}

.missing-text {
  font-style: italic;
}

.card-deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card-deck .event {
  max-width: 25%;
}

.events .event {
  margin: 30px;
}

.event {
  margin: 30px 0;
}

.boats-and-waitlist {
  display: flex;
}

.waitlist-item {
  margin: 10px 0;
}

.waitlist-item button {
  margin-left: 5px;
}

.waitlist {
  flex-shrink: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 10px;
  max-width: 30%;
}

.waitlist .card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login {
  max-width: 40%;
}

.login-container {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.modal-error {
  color: red;
  font-size: 0.7em;
}

.date-and-time {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: space-between;
}

.venue-name {
  font-weight: bold;
}

.event-section-title {
  color: gray;
  font-size: 1.5em;
  text-align: center;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 10px;
  border-color: lightgray;
  margin-top: 20px;
}

.big-event .event {
  max-width: 100%;
}

.action-row {
  border-style: solid;
  border-color: darkgray;
  border-width: 0 0 2px 0;
}

.checklist {
  padding-bottom: 10px;
}

@media (max-width: 600px) {
  .card-deck .event {
    max-width: 100%;
  }

  .event-and-signups {
    flex-direction: column;
  }

  .waitlist {
    margin-left: 0px;
  }

  .boats .boat {
    width: 100%;
    margin-right: 0px;
  }

  .boats {
    margin-right: 0px;
  }

  .login-container {
    margin: 10px 0px 0px 0px;
  }

  .login {
    max-width: 100%;
  }

  .events .event {
    margin: 30px 0px 0px 0px;
  }

  .event-summary .card-img-top {
    height: 200px;
  }

  .waitlist {
    max-width: 100%;
  }
}

